import QR from '../images/qr.svg'

const QrCat = () => (
  <div className="widget">
    <div className="qr-container">
      <img className="qr" alt='' src={QR}></img>
    </div>
  </div>
)

export default QrCat