import React from "react";
import gif from '../../images/fire.gif'
import "./progress-bar.css";

function ProgressBar({donations, goal, milestones}) {
    const totalAmount = milestones?.reduce((sum, milestone) => sum + milestone.amount, 0);
    const progressPercentage = Math.min((donations / totalAmount) * 100, 100);

    return (
        <div className="container">
            <h1 className="progress-bar-heading">Charity Compendium</h1>
            <div className="progress-bar-subheading">₴{donations.toLocaleString('en-GB')}/₴{goal.toLocaleString('en-GB')}</div>
            <div className="progress-bar">
                <div className="progress" style={{width: `${progressPercentage}%`}}>
                <img className="fire-gif" src={gif} height={100} alt="" />
                </div>
                {milestones.map((milestone, index) => {
                    const isAchieved = donations >= milestone.cumulativeAmount
                    return (
                        <div
                            key={index}
                            className={`milestone-divider ${isAchieved ? 'achieved' : ''}`}
                            style={{left: `${(milestone.cumulativeAmount / totalAmount) * 100}%`}}
                        >
                            <span className="milestone-order">{milestone.order}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ProgressBar;