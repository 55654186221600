import React from 'react';
import './about-section.css';

const AboutSection = () => {
  return (
    <div className="about">
      <div className="container">
        <p>Кожному з нас дуже прикро, що Компендіум в цьому році вийшов сумнівний, а ще більше шкода через те, що українських команд немає на The International.
        </p>
        <p>Але незалежно від того, знаєте ви про Доту чи ні — у нас є одна українська команда, котра розвалює набагато краще, аніж будь-який інший склад на The International. І це підрозділ АЗОВ, котрі боронять нашу країну, наш трон проти ворожих окупантів.
        </p>
        <p>Тому ми створили унікальний, український Компендіум, де ви зможете задонатити на збір, виграти круті призи і найголовніше — допомогти хлопцям і дівчатам з АЗОВу у придбанні апнутих кур'єрів (FPV дронів), котрі будуть приносити багато чого цікавого окупантам.
        </p>
      </div>
    </div>
  )
}

export default AboutSection;