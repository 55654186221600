import "./footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <h4 className="header footer-header">Соціальні мережі</h4>
                <div className="socials">
                    <div className="social">
                        <a href="https://www.twitch.tv/ghostik" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 24"><path style={{stroke:"none",fillRule: "nonzero", fill:"#fff", fillOpacity: "1"}} d="m33.367 5.773-1.601 1.555h-2.512L27.883 8.66V7.328h-2.059V.887h7.543ZM24.91 0l-.457 1.777V9.77h2.059v1.109h1.14l1.145-1.11h1.828l3.656-3.55V0Zm0 0" /><path style={{stroke:"none",fillRule: "nonzero",fill:"#fff",fillOpacity: "1"}} d="M28.34 5.328h.914V2.664h-.914Zm2.512 0h.914V2.664h-.914Zm8.23 10.215-1.602-1.555h-2.972v-1.996h-2.512v9.102h2.512V16.43h2.058v4.664h2.516Zm-8-1.555h-3.887l-1.597 1.555v3.996l1.597 1.555h3.887v-2.442h-2.973V16.43h2.973Zm-6.398 0h-2.059v-1.996h-2.516v7.547l1.602 1.555h2.973v-2.442h-2.059V16.43h2.059Zm-5.489-1.996h-2.511v1.11h2.511Zm0 1.996h-2.511v7.106h2.511Zm-3.425 0h-2.516v4.664h-.914v-4.664H9.828v4.664h-.914v-4.664H6.398v7.106h7.77l1.602-1.555Zm-10.286 0H3.43v-1.996H.914v7.547l1.602 1.555h2.968v-2.442H3.43V16.43h2.054Zm34.508 1.11v6.66l-3.426 2.222h-2.285v-1.109l-1.601 1.11h-2.055v-1.11l-1.145 1.11h-3.656l-1.14-1.11-.23 1.11h-3.2l-1.309-1.11-.074 1.11h-3.625l-.129-1.11-.98 1.11h-5.54l-1.14-.446v.445H5.484l-3.425-2L0 19.984v-8.882h4.344l2.054 2h9.372v-2h7.77v2h2.058v1.109l1.14-1.11h2.285l2.059-2h4.34v2h2.516Zm0 0" /></svg>
                        </a>
                    </div>
                    <div className="social">
                        <a href="https://www.tiktok.com/@ghostikdota" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path style={{stroke:"none",fillRule:"nonzero",fill:"#fff",fillOpacity:"1"}} d="M22.5 0h4.95c.359 1.79 1.35 4.043 3.09 6.281C32.237 8.473 34.491 10 37.5 10v5c-4.383 0-7.676-2.035-10-4.574V27.5C27.5 34.402 21.902 40 15 40S2.5 34.402 2.5 27.5 8.098 15 15 15v5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5Zm0 0" /></svg>
                        </a>
                    </div>
                    <div className="social">
                        <a href="https://www.youtube.com/@ghostikua" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path style={{stroke:"none",fillRule: "nonzero",fill:"#fff",fillOpacity: "1"}} d="M7.754 0h2.398l1.649 6.172L13.328 0h2.422l-2.773 9.176v6.261H10.59V9.177Zm10.988 3.953c-1.863 0-3.101 1.235-3.101 3.059v5.582c0 2.008 1.05 3.054 3.101 3.054 1.703 0 3.043-1.14 3.043-3.054V7.012c0-1.782-1.328-3.059-3.043-3.059Zm.887 8.547c0 .621-.317 1.078-.887 1.078-.586 0-.926-.48-.926-1.078V7.203c0-.625.29-1.086.883-1.086.653 0 .93.45.93 1.086Zm7.883-8.45v8.641c-.262.325-.836.856-1.246.856-.453 0-.563-.309-.563-.766v-8.73h-2.117v9.515c0 1.125.344 2.036 1.477 2.036.64 0 1.53-.336 2.449-1.422v1.254h2.113V4.05Zm3.672 23.098c-.75 0-.903.524-.903 1.27v1.098h1.782v-1.098c0-.734-.153-1.27-.88-1.27Zm-7.84.067a1.538 1.538 0 0 0-.418.328v6.758c.164.176.324.304.48.383.328.168.809.18 1.032-.114.117-.152.175-.398.175-.746v-5.597c0-.368-.074-.645-.215-.836-.246-.32-.703-.356-1.054-.176Zm8.043-8.66c-4.34-.293-18.442-.293-22.774 0-4.691.32-5.246 3.152-5.281 10.613.035 7.445.586 10.289 5.281 10.61 4.332.296 18.434.296 22.774 0 4.691-.321 5.246-3.153 5.281-10.61-.035-7.445-.586-10.293-5.281-10.613Zm-20.54 17.808H8.579V23.797H6.227v-2.133h6.968v2.133h-2.347Zm8.079 0H16.91v-1.195a4.468 4.468 0 0 1-1.16 1.008c-1.09.625-2.578.61-2.578-1.59v-9.063h2.012v8.313c0 .437.105.73.539.73.39 0 .937-.507 1.183-.812v-8.23h2.016v10.84Zm7.762-2.246c0 1.344-.504 2.387-1.844 2.387-.739 0-1.356-.27-1.914-.973v.832h-2.035V21.664h2.035v4.734c.453-.554 1.07-1.011 1.793-1.011 1.476 0 1.965 1.246 1.965 2.715Zm7.449-2.918H30.28v2.047c0 .813.07 1.516.88 1.516.85 0 .901-.575.901-1.516v-.754h2.075v.817c0 2.086-.895 3.351-3.02 3.351-1.926 0-2.91-1.402-2.91-3.351v-4.871c0-1.88 1.242-3.188 3.063-3.188 1.933 0 2.867 1.227 2.867 3.188Zm0 0" /></svg>
                        </a>
                    </div>
                    <div className="social">
                        <a href="https://t.me/ghostikpub" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><path style={{stroke:"none",fillRule: "nonzero",fill:"#fff", fillOpacity: "1"}} d="M40 20c0 11.047-8.953 20-20 20S0 31.047 0 20 8.953 0 20 0s20 8.953 20 20Zm-19.281-5.234c-1.946.808-5.836 2.484-11.668 5.023-.942.375-1.442.746-1.485 1.106-.078.609.688.847 1.723 1.175l.438.137c1.02.332 2.394.719 3.109.734.648.016 1.371-.25 2.168-.8 5.45-3.676 8.262-5.532 8.437-5.575.125-.027.297-.062.414.043.118.102.106.297.094.352-.078.32-3.07 3.102-4.617 4.543-.48.45-.824.766-.895.84a26.3 26.3 0 0 1-.468.465c-.953.914-1.66 1.597.035 2.718.82.54 1.473.985 2.125 1.426.71.488 1.422.969 2.34 1.574.234.149.457.313.676.47.828.589 1.574 1.116 2.492 1.034.535-.05 1.09-.55 1.367-2.05.664-3.544 1.965-11.215 2.266-14.38.02-.261.007-.527-.032-.785a.835.835 0 0 0-.285-.543 1.34 1.34 0 0 0-.777-.234c-.75.012-1.906.414-7.457 2.727Zm0 0" /></svg>
                        </a>
                    </div>
                </div>
                <div className="disclaimer">
                    <h3 className="disclaimer-header">Disclaimer</h3>
                    <p>
                        This website is an independent project created for charitable purposes and is not affiliated with, endorsed by, or sponsored by Valve Corporation, the creator of Dota 2. All assets from Dota 2 used on this website, including but not limited to fonts, images, and characters, are the property of Valve Corporation. We have made every effort to use these assets in a manner compliant with fair use and other applicable laws. Any use of Valve Corporation’s trademarks or copyrighted material is not intended to infringe upon the rights of Valve Corporation.
                        If you are the rightful owner of any of the content used on this website and wish for it to be removed, please contact us at <a href="mailto:cryptonov+charity@gmail.com">cryptonov+charity@gmail.com</a> and we will promptly address your request.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;