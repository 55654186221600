import "./partners-section.css";
import e_gaming from "../../../images/2E_Gaming.png"
import comms from "../../../images/comms.png"
import favbet from "../../../images/favbet.png"
import logitech from "../../../images/logitech.png"

const PartnersSection = () => {
    return (
        <section className="partners">
            <div className="container">
                <h3 className="header partners-header">
                    Партнери
                </h3>
                <div className="partners-list">
                    <div className="partner">
                        <img src={favbet} loading="lazy" alt="Logitech logo" />
                    </div>
                    <div className="partner">
                        <img src={logitech} loading="lazy" alt="Logitech logo" />
                    </div>
                    <div className="partner">
                        <img src={comms} loading="lazy" alt="Logitech logo" />
                    </div>
                    <div className="partner">
                        <img src={e_gaming} loading="lazy" alt="Logitech logo" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection;