import { useEffect, useState } from "react";
import ProgressBar from "../../components/progress-bar/progress-bar";
import { milestones } from "../../App";
import './ObsWidget.css'
import QrCat from "../../components/QrCat";

const ObsWidget = () => {
  const [donations, setDonations] = useState(0); // Example donation amount
  const [goal, setGoal] = useState(0); // Example donation amount
 
  const mappedMilestones = milestones.reduce((acc, milestone, index) => {
      acc.push({...milestone, cumulativeAmount: acc[index - 1]?.cumulativeAmount + milestone.amount || milestone.amount, order: index + 1 })
      return acc;
  }, []);

  const url =
    "https://corsproxy.io/?" +
    encodeURIComponent("https://send.monobank.ua/api/handler");
  const creds = {
      c: "hello",
      clientId: "92t3ZAYkma",
      referer: "",
      Pc: "123"
  };

  const fetchJarData = async () => {
      const res = await fetch(url, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify(creds)
      });
      const { jarAmount, jarGoal } = await res.json();
      setGoal(Math.floor(jarGoal / 100));
      setDonations(Math.floor((jarAmount || 0) / 100));
  };


  useEffect(() => {
      let interval = null;
      fetchJarData()
      interval = setInterval(() => { fetchJarData() }, 5000);
      return () => { clearInterval(interval) }
  }, [])

  return (
    <div className="widget-wrapper">
      <ProgressBar donations={donations} milestones={mappedMilestones} goal={goal} />
      <QrCat />
    </div>

  )
}

export default ObsWidget
