// HeroSection.js
import React from 'react';
import './hero-section.css';
import DonateButton from '../../DonateButton';

const HeroSection = () => {
    return (
        <div className="hero-section">
            <div className="container">
              <div className="hero-content">
                <h1 className="header hero-header">БАЗОВАНИЙ <br/> КОМПЕНДІУМ</h1>
                <p className="hero-subtitle">Це той Компендіум на який ми РЕАЛЬНО заслуговуємо.</p>
                {/* Additional about-section or buttons */}
              </div>
            </div>
            <DonateButton />
        </div>
    );
};

export default HeroSection;
