// Importing necessary libraries and styles
import React, {useEffect, useRef, useState} from 'react';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import {
//     getFunctions,
//     httpsCallable,
//     connectFunctionsEmulator,
// } from "firebase/functions";

import './App.css';
import MilestoneList from "./components/milestones/milestones";
import HeroSection from "./components/sections/hero-section/hero-section";
import AboutSection from "./components/sections/about-section/about-section";
import PartnersSection from "./components/sections/partners-section/partners-section";
import ProgressBar from "./components/progress-bar/progress-bar";
import Border from './components/ui/border/border';
import Footer from './components/footer/footer';
import PrizesSection from './components/sections/prizes-section/prizes-section';
import QrCat from './components/QrCat';
import ReactGA from 'react-ga4';


// const {
//     REACT_APP_FIREBASE_APIKEY,
//     REACT_APP_FIREBASE_AUTHDOMAIN,
//     REACT_APP_FIREBASE_PROJECTID,
//     REACT_APP_FIREBASE_STORAGEBUCKET,
//     REACT_APP_FIREBASE_MESSAGINGSENDERID,
//     REACT_APP_FIREBASE_APPID,
//     REACT_APP_ENV,
// } = process.env;

export const milestones = [
    { scrollId: '1', amount: 100000, prize: '10x Аркан від COMMS'},
    { scrollId: '2', amount: 100000, prize: '3x Пак від FAVBET (худі, м\'яч та фотокартка Монте з підписом)'},
    { scrollId: '3', amount: 100000, prize: 'Оперативна пам\'ять Kingston DDR5 16гб'},
    { scrollId: '3', amount: 100000, prize: 'Оперативна пам\'ять Kingston DDR5 32гб'},
    { scrollId: '4', amount: 100000, prize: '4x Мишка Logitech G604 Lightspeed'},
    { scrollId: '5', amount: 150000, prize: 'Крісло Bushido 2E Gaming'},
    { scrollId: '6', amount: 150000, prize: 'Крісло Hibagon 2E Gaming'},
    { scrollId: '7', amount: 150000, prize: 'Крісло Ogama 2E Gaming'},
    { scrollId: '8', amount: 150000, prize: '3x Крісло Basan 2E Gaming'},
    { scrollId: '9', amount: 150000, prize: 'Футболка К12 (Розвідка АЗОВ)'},
    { scrollId: '10', amount: 150000, prize: 'Прапор К12 (Розвідка АЗОВ)'},
    { scrollId: '11', amount: 150000, prize: 'Тубус від Мухи'},
    { scrollId: '12', amount: 150000, prize: 'Ящик від Браунінгу'},
    { scrollId: '13', amount: 150000, prize: '10x Крафтовий Aegis'},
    { scrollId: '14', amount: 150000, prize: 'Прапор з підписами АЗОВу'},
];

ReactGA.initialize("G-ZS7QWTKK5X")

// Main App component
function App() {
    const initialized = useRef(false);
    const [donations, setDonations] = useState(0); // Example donation amount
    const [goal, setGoal] = useState(0); // Example donation amount
    console.log(donations)
    // const firebaseConfig = {
    //     apiKey: REACT_APP_FIREBASE_APIKEY,
    //     authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
    //     projectId: REACT_APP_FIREBASE_PROJECTID,
    //     storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
    //     messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID,
    //     appId: REACT_APP_FIREBASE_APPID,
    // };
    //
    // const app = initializeApp(firebaseConfig);
    // const functions = getFunctions(app);
    //
    // if (REACT_APP_ENV === "development") {
    //     connectFunctionsEmulator(functions, "localhost", 5001);
    // }

    const mappedMilestones = milestones.reduce((acc, milestone, index) => {
        acc.push({...milestone, cumulativeAmount: acc[index - 1]?.cumulativeAmount + milestone.amount || milestone.amount, order: index + 1 })
        return acc;
    }, []);

    const url =
      "https://corsproxy.io/?" +
      encodeURIComponent("https://send.monobank.ua/api/handler");
    const creds = {
        c: "hello",
        clientId: "92t3ZAYkma",
        referer: "",
        Pc: "123"
    };

    const fetchJarData = async () => {
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(creds)
        });
        const { jarAmount, jarGoal } = await res.json();
        setGoal(Math.floor(jarGoal / 100));
        setDonations(Math.floor((jarAmount || 0) / 100));
    };


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Sponsor" });
        let interval = null;
        fetchJarData()
        interval = setInterval(() => { fetchJarData() }, 5000);
        return () => { clearInterval(interval) }
    }, [])

    const trackClick = () => {
        // ReactGA.event({
        //     category: 'Button',
        //     action: 'page_click',
        //     label: 'page'
        //   });
    }

    return (
        <div className="App" onClick={() => trackClick()}>
            <HeroSection />
            <AboutSection/>
            <Border/>
            <section className="App-header">
                <div className="container">
                  <ProgressBar donations={donations} milestones={mappedMilestones} goal={goal} />
                  <MilestoneList donations={donations} milestones={mappedMilestones} />
                </div>
            </section>
            <Border/>
            <PrizesSection />
            <Border/>
            <PartnersSection />
            <Border/>
            <Footer/>
            <QrCat />
        </div>
    );
}



export default App;
