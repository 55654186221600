import React from "react";
import './milestones.css'
import DonateButton from "../DonateButton";

function MilestoneList({milestones, donations}) {
    return (
        <div className="milestone-list">
            {milestones.map((milestone, index) => (
                <Milestone key={index} {...milestone} donations={donations} className="milestone" />
            ))}
            <DonateButton />
        </div>
    );
}

function Milestone({ order, amount, cumulativeAmount, prize, donations, scrollId }) {
    const isAchieved = cumulativeAmount <= donations;
    const percentage = isAchieved ? 'Achieved' : Math.max(((donations - cumulativeAmount + amount) / amount) * 100, 0);

    const scroll = (id) => {
      const section = document.querySelector(`#prize-${id}`);
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
        <div className="milestone">
          <div className="container" onClick={() => scroll(scrollId)}>
            <div className="milestone-progress-container">
            <div className="order">{order}</div>
              <div className="milestone-progress">
                <div
                  className={`progress ${isAchieved ? 'achieved' : ''}`}
                  style={{ width: `${percentage}%` }}
                  ></div>
                {isAchieved ? (
                    <span className="achieved">ДОСЯГНУТО!</span>
                  ) : <span className="percentage">{parseInt(percentage)}%</span>}
              </div>
            </div>
            <div className="prize">
              <div className="amount">₴{ cumulativeAmount.toLocaleString() }</div>
              <div className="description">{ prize }</div>
            </div>
          </div>
        </div>
    )
}

export default MilestoneList;