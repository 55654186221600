import './prizes-section.css'
import zeusImg from '../../../images/prizes/zeus.png'
import favbet from '../../../images/prizes/favbet.png'
import kingston from '../../../images/prizes/kingston.png'
import logitech from '../../../images/prizes/logitech.png'
import twoEbushido from '../../../images/prizes/2ebushido.png'
import twoEhibagon from '../../../images/prizes/2eHIBAGON.png'
import twoEogama from '../../../images/prizes/2eOGAMA.png'
import twoEbasan from '../../../images/prizes/2ebasan.png'
import azovk12tshirt from '../../../images/prizes/azovk12tshirt.png'
import azovk12flag from '../../../images/prizes/azovk12flag.png'
import azovk12grenade from '../../../images/prizes/grenade.png'
import box from '../../../images/prizes/box.png'
import aegis from '../../../images/prizes/aegis.png'
import uaFlag from '../../../images/prizes/uaflag.png'
import DonateButton from '../../DonateButton'


const Prize = ({ rarity, rarityTitle, imageSrc, title, description, id }) => (
  <div id={`prize-${id}`} className='container reward-wrapper'>
    <div className={`reward-image-wrapper ${rarity}`}>
      <img className='reward-image' src={imageSrc} alt="" />
      <div className={`reward-rarity ${rarity}`}></div>
    </div>
    <div className='reward-description-wrapper'>
      <h2 className='reward-title'>{title}</h2>
      <p className='reward-rarity-text'>
        Rarity: <span className={rarity}>{rarityTitle}</span>
      </p>
      <p className='reward-description'>{description}</p>
    </div>
  </div>
)

const prizeListData = [
  { id: 1,
    rarity: 'arcana',
    rarityTitle: 'Arcana', 
    title: '10х Аркан від COMMS',
    description: 'Майже як Swag Bag, але краще, адже вам не треба зіграти одну гру 💀',
    imageSrc: zeusImg,
  },
  { id: 2,
    rarity: 'mythical',
    rarityTitle: 'Mythical', 
    title: '3х Пак від FAVBET',
    description: "Худі, м'яч та фотокартка Монте з підписом. Тут нічого додати, це база! 🗿",
    imageSrc: favbet,
  },
  { id: 3,
    rarity: 'rare',
    rarityTitle: 'DDR5', 
    title: "Kingston DDR5 16гб та 32гб",
    description: 'Заради такої оперативи можна і весь комп оновити. Швидка, гарнюща, а головне виграна з такого розіграшу!⭐',
    imageSrc: kingston,
  },
  { id: 4,
    rarity: 'legendary',
    rarityTitle: 'Legendary', 
    title: '4х Мишка Logitech G604 Lightspeed',
    description: 'Мишка, яка не потребує представлення. Ммр підіймається лише від погляду на цю красуню. 💅',
    imageSrc: logitech,
  },
  { id: 5,
    rarity: 'immortal',
    rarityTitle: 'Immortal', 
    title: 'Крісло Bushido 2E Gaming',
    description: 'Ігрове крісло, в якому поєднано зручність, підтримку та стильний дизайн. ',
    imageSrc: twoEbushido,
  },
  { id: 6,
    rarity: 'immortal',
    rarityTitle: 'Immortal', 
    title: 'Крісло Hibagon 2E Gaming',
    description: 'Виняткове поєднання стилю, комфорту та функціональності, розроблене для інтенсивних ігрових сесій і продуктивної роботи за комп’ютером.',
    imageSrc: twoEhibagon,
  },
  { id: 7,
    rarity: 'immortal',
    rarityTitle: 'Immortal', 
    title: 'Крісло Ogama 2E Gaming',
    description: 'Крісло з РГБ підсвіткою. Ну ви таке бачили??',
    imageSrc: twoEogama,
  },
  { id: 8,
    rarity: 'immortal',
    rarityTitle: 'Immortal', 
    title: '3х Крісла Basan 2E Gaming',
    description: 'Це те, що юзає син маминої подруги: преміальні матеріали, сталева надійність і переможний функціонал. 😱',
    imageSrc: twoEbasan,
  },
  { id: 9,
    rarity: 'favored',
    rarityTitle: 'AZOV', 
    title: 'Футболка К12',
    description: 'Футболка з лого розвідки К12 Азов. В цій футболці дасти на тебе не діють.',
    imageSrc: azovk12tshirt,
  },
  { id: 10,
    rarity: 'favored',
    rarityTitle: 'AZOV', 
    title: 'Прапор К12',
    description: 'Прапор розвідки К12 Азов. Цей прапор втік з багажника ще до того як туди потрапив.',
    imageSrc: azovk12flag,
  },
  { id: 11,
    rarity: 'favored',
    rarityTitle: 'AZOV', 
    title: 'Тубус від Мухи',
    description: 'Муха-шведська. Перерівена AWF.',
    imageSrc: azovk12grenade,
  },
  { id: 12,
    rarity: 'favored',
    rarityTitle: 'AZOV', 
    title: 'Ящик від Браунінгу',
    description: 'Ящик під мангуси і тангуси, і всякі інші приколяси. ',
    imageSrc: box,
  },
  { id: 13,
    rarity: 'ancient',
    rarityTitle: 'Crafted', 
    title: '10х Крафтовий Aegis',
    description: 'Унікальний Аегіс в кольорах Українського прапора.',
    imageSrc: aegis,
  },
  { id: 14,
    rarity: 'favored',
    rarityTitle: 'AZOV', 
    title: 'Прапор з підписами АЗОВу',
    description: 'Прапор з підписами бійців, котрі захищали Маріуполь.',
    imageSrc: uaFlag,
  },

]


const PrizesSection = () => {
  return (
    <div className="prizes-section">
      <h3 className="header prizes-header">
        НАГОРОДИ
      </h3>
      <p>Для участі у розіграші нагород зробіть будь-який донат від 100 грн.</p>
      <div className='prizes-list'>
       {prizeListData.map((item) => (
          <Prize key={item.id} {...item} />
       ))}
      </div>
      <DonateButton />
    </div>
  )
}

export default PrizesSection;
